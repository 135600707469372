import React from "react";
import { Link } from "gatsby";
import classNames from "../../utils/twClassNames";
import { camelCaseToTitle } from "../../utils/regex";
import { waitlistCta } from "../../data/ctas";

const CellLineInfo = ({ cellLineInfoTitle, cellLineData }) => {
  const title = camelCaseToTitle(cellLineInfoTitle);
  return cellLineData ? (
    <div className="grid grid-cols-3 py-4 border-t md:py-8 border-zinc-300 first:border-t-0">
      <p className="uppercase text-slate-500 md:text-sm">{title}</p>
      <span className="col-span-2 md:text-sm">{cellLineData}</span>
    </div>
  ) : (
    <> </>
  );
};

const cellLineInfoData = ["species", "subSpecies", "cellType"];
const CellLineCard = ({ cellLine }) => {
  return (
    <li
      key={cellLine.id}
      className="relative grid grid-cols-2 gap-4 p-8 transition ease-in-out bg-white border rounded-3xl shadow hover:scale-[1.01] hover:shadow-lg duration-500"
    >
      <Link
        to={`/waitlist`}
        className={classNames(
          "absolute inset-0 rounded-3xl",
          "focus:z-10 focus:outline-none focus:ring-2 ring-blue-400 "
        )}
      >
        <span className="sr-only">Join our waitlist</span>
      </Link>
      <div className="col-span-2 space-y-2">
        <p className="text-xs font-semibold tracking-wide uppercase text-zinc-600 xl:text-base">
          {cellLine.cellLine}
        </p>
        <h3 className="text-2xl font-bold leading-5 tracking-tight xl:text-5xl md:text-4xl text-slate-900">
          {cellLine.name}
        </h3>
      </div>

      <div className="justify-center col-span-2 col-start-1 text-xs font-normal leading-4 ">
        {cellLineInfoData.map((cellLineInfo, idx) => (
          <CellLineInfo
            cellLineInfoTitle={cellLineInfo}
            cellLineData={cellLine[cellLineInfo]}
            key={idx}
          />
        ))}
      </div>

      <div className="flex flex-col justify-between h-full col-span-2">
        <Link
          className={classNames(
            "p-3 mt-auto text-blue-600  border border-blue-600 rounded-full z-10 font-medium text-center",
            "hover:bg-blue-600 hover:text-white cursor-pointer"
          )}
          to="/waitlist"
        >
          {waitlistCta}
        </Link>
      </div>
    </li>
  );
};

export default CellLineCard;
