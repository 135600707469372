import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
const popularCellLines = [
  { name: "CULT-CNm1s", image: "chickenProfile" },
  { name: "CULT-CEa1e", image: "cattleProfile" },
  { name: "CULT-PGm1e", image: "pigProfile" },
];
const RelatedCellLinesCard = ({ _, position }) => {
  const isEvenPosition = position % 2 === 0;
  const span = isEvenPosition ? "lg:col-span-2" : "lg:col-span-1";
  const gridCols = isEvenPosition ? "lg:grid-cols-3" : "grid-cols-1";
  const height = isEvenPosition ? "lg:h-64" : "lg:h-auto";

  const data = useStaticQuery(
    graphql`
      query {
        chickenProfile: imgixImage(url: "/chicken-profile.jpg") {
          gatsbyImageData(
            layout: CONSTRAINED
            aspectRatio: 2.44444
            width: 400
            imgixParams: { fit: "min", crop: "entropy", sat: 30 }
          )
        }
        cattleProfile: imgixImage(url: "/cattle-profile.jpg") {
          gatsbyImageData(
            layout: CONSTRAINED
            aspectRatio: 2.44444
            width: 400
            imgixParams: { fit: "min", crop: "entropy", sat: 30 }
          )
        }
        pigProfile: imgixImage(url: "/pig-profile.jpg") {
          gatsbyImageData(
            layout: CONSTRAINED
            aspectRatio: 2.44444
            width: 400
            imgixParams: { fit: "min", crop: "entropy", sat: 30 }
          )
        }
      }
    `
  );
  return (
    <li
      className={`flex flex-col p-8 ${span} border rounded-2xl bg-white shadow`}
    >
      <h3 className="mb-2 ml-4 text-lg font-semibold tracking-tight xl:mb-4 text-slate-900">
        Popular Cell Lines
      </h3>
      <div className={`grid flex-grow gap-2 xl:gap-4 ${gridCols}`}>
        {popularCellLines.map((x) => (
          <Link
            key={x.name + "_popular_lines"}
            className={`relative flex h-24 cursor-pointer ${height} group`}
            to="/waitlist"
          >
            <div className="z-10 p-4 rounded-l-2xl">
              <h4 className="text-lg font-semibold tracking-tight text-white">
                {x.name}
              </h4>
            </div>
            <div className="absolute z-0 w-full h-full overflow-hidden rounded-2xl">
              <GatsbyImage
                image={data[x.image].gatsbyImageData}
                className="w-full h-full transition duration-500 bg-slate-900 group-hover:scale-110"
                alt=""
              />
            </div>
          </Link>
        ))}
      </div>
    </li>
  );
};

export default RelatedCellLinesCard;
