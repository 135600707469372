import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Seo from "../components/seo/Seo";
import FullWidthPictureHero from "../components/shared/heroes/FullWidthPictureHero";
import Layout from "../components/shared/Layout";
import { commonNameCellLines } from "../data/cell-lines";
import CellLineCard from "../components/shared/CellLineCard";
import RelatedCellLinesCard from "../components/common-name/RelatedCellLinesCard";
import { waitlistCta } from "../data/ctas";

const CommonNameCellsTemplate = ({
  pageContext: { commonName, socialShareCard },
}) => {
  const lowercaseName = commonName.name.toLowerCase();
  const imgUri = `https://cultura.imgix.net/${lowercaseName}-food-hero.jpg`;
  const cellLines = Object.values(commonNameCellLines(commonName.name)).flat();
  const count = cellLines.length;

  const data = useStaticQuery(
    graphql`
      query {
        chickenHero: imgixImage(url: "/chicken-food-hero.jpg") {
          gatsbyImageData(
            layout: FULL_WIDTH
            aspectRatio: 2.44444
            backgroundColor: "black"
            imgixParams: { fit: "crop", crop: "entropy" }
          )
        }
        cattleHero: imgixImage(url: "/cattle-food-hero.jpg") {
          gatsbyImageData(
            layout: FULL_WIDTH
            aspectRatio: 2.44444
            backgroundColor: "black"
            imgixParams: { fit: "crop", crop: "entropy" }
          )
        }
        pigHero: imgixImage(url: "/pig-food-hero.jpg") {
          gatsbyImageData(
            layout: FULL_WIDTH
            aspectRatio: 2.44444
            backgroundColor: "black"
            imgixParams: { fit: "crop", crop: "entropy" }
          )
        }
        tunaHero: imgixImage(url: "/tuna-food-hero.jpg") {
          gatsbyImageData(
            layout: FULL_WIDTH
            aspectRatio: 2.44444
            backgroundColor: "black"
            imgixParams: { fit: "crop", crop: "entropy" }
          )
        }
        salmonHero: imgixImage(url: "/salmon-food-hero.jpg") {
          gatsbyImageData(
            layout: FULL_WIDTH
            aspectRatio: 2.44444
            backgroundColor: "black"
            imgixParams: { fit: "crop", crop: "entropy" }
          )
        }
      }
    `
  );
  const heroImage = data[lowercaseName + "Hero"].gatsbyImageData;
  return (
    <Layout>
      <Seo
        title={commonName.name + " Cell Lines for Cultured Meat"}
        image={{
          src: socialShareCard,
          width: 1200,
          height: 630,
        }}
        description={`${commonName.name} cell lines are for cultured meat.`}
      />
      <FullWidthPictureHero
        title={commonName.name + " Cells"}
        copy="Cultura Cells are the most efficient cell lines."
        cta={waitlistCta}
        imgUri={imgUri}
        imgAlt={commonName.name}
        linkUri="/waitlist/"
        image={heroImage}
      />

      <section id="cell-lines" className="min-h-screen pt-8 pb-16 bg-zinc-100">
        <div className="relative px-4 mx-auto xl:container xl:p-0">
          <div className="w-full px-8 mb-4">
            <h2 className="text-xl font-bold tracking-tight text-slate-900 lg:text-3xl">
              {commonName.name} Cell Lines
            </h2>
          </div>
          <ul className="grid gap-4 lg:grid-cols-2">
            {cellLines.map((cellLine) => (
              <CellLineCard cellLine={cellLine} key={cellLine.id} />
            ))}
            <RelatedCellLinesCard position={count} />
          </ul>
        </div>
      </section>
    </Layout>
  );
};
export default CommonNameCellsTemplate;
